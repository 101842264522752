@import "_placeholders.scss";

.add-location {
	@extend %page-base;

	label {
		width: 140px;
	}

	#map {
		width: 100%;
		height: 500px;
		background-color: #3b8;
	}

	#location_group_id {
		height: 26px;
	}

	.location-tags {
		label {
			display: block;
			margin-bottom: 2px;
			margin-top: 2px;

			&[for="address"] {
				margin-top: 0;
			}
		}

		#location_tags {
			height: 365px;
			min-height: 210px;
		}
	}

	.map-and-location-tags {
		margin-top: 5px;
	}

	button.btn {
		margin-top: 5px;
		float: right;
	}
}

@media screen and (max-width: 300px) {
	div.add-location {
		padding-left: 2px;
		padding-right: 2px;
		h1 {
			font-size: 9vw;
		}
		label {
			display: block;
		}
		input {
			display: block;
			width: 100%;
		}
		#map {
			height: 250px;
		}
		.location-tags {
			#location_tags {
				width: 100%;
				height: 212px;
			}
			button.btn {
				margin-bottom: 5px;
			}
		}
	}
}

@media screen and (min-width: 300px) and (max-width: 500px) {
	.add-location {
		h1 {
			font-size: 9vw;
		}

		label {
			width: 110px;
		}
		select, input {
			width: calc(100vw - 155px);
		}

		.map-and-location-tags {
			#map {
				height: 300px;
			}

			.location-tags {
				label {
					width: 100%;
				}

				input, select {
					width: 100%;
				}

				#location_tags {
					height: 212px;
				}
				button.btn {
					margin-bottom: 5px;
				}
			}
		}
	}
}

@media screen and (max-width: 770px) {
	.add-location {
		.map-and-location-tags {
			#map {
				height: 300px;
			}

			.location-tags {
				label {
					width: 100%;
				}

				input, select {
					width: 100%;
				}

				#location_tags {
					height: 212px;
				}
			}
		}
	}
}

@media screen and (min-width: 500px) and (max-width: 770px) {
	.add-location {
		[for="name"] {
			width: 45px;
		}
		[for="phone_number"] {
			text-align: center;
			width: 110px;
		}
		#name {
			width: calc(50vw - 80px);
		}
		#phone_number {
			width: calc(50vw - 125px);
		}
		.map-and-location-tags {
			#map {
				height: 300px;
			}
		}
	}
}

@media screen and (min-width: 770px) and (max-width: 991px) {
	.add-location {
		[for="name"] {
			width: 50px;
		}
		[for="phone_number"] {
			text-align: center;
			width: 110px;
		}
		select, input {
			width: 278px;
		}
		.map-and-location-tags {
			display: flex;

			.location-tags {
				width: 285px;
				padding-left: 7px;
				flex-grow: 0;
				flex-shrink: 0;

				#location_tags {
					height: 210px;
					height: calc(100vh - 340px);
				}
			}

			#map {
				height: calc(100vh - 130px);
			}
		}
		&.with-errors {
			.map-and-location-tags {
				#map {
					height: calc(100vh - 202px);
				}
				.location-tags {
					#location_tags {
						height: calc(100vh - 412px);
					}
				}
			}
		}
	}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
	.add-location {
		[for="name"] {
			width: 70px;
		}

		[for="phone_number"] {
			text-align: center;
			width: 120px;
		}

		select, input {
			width: 374px;
		}

		.map-and-location-tags {
			display: flex;

			.location-tags {
				width: 379px;
				padding-left: 5px;
				flex-grow: 0;
				flex-shrink: 0;

				#location_tags {
					height: initial;
				}
			}

			#map {
				height: calc(100vh - 140px);
			}
		}
		&.with-errors {
			.map-and-location-tags {
				#map {
					height: calc(100vh - 212px);
				}
			}
		}
	}
}

@media screen and (min-width: 1200px) {
	.add-location {
		[for="phone_number"] {
			padding-left: 25px;
		}

		input, select {
			width: 429px;
		}

		.map-and-location-tags {
			display: flex;

			#map {
				flex-grow: 1;
				height: calc(100vh - 130px);
			}

			#location_tags {
				height: 300px; /* in case calc isn't supported */
				height: calc(100vh - 400px);
			}

			.location-tags {
				width: 435px;
				padding-left: 6px;
				flex-shrink: 0;
				flex-grow: 0;
			}
		}
		&.with-errors {
			.map-and-location-tags {
				#map {
					height: calc(100vh - 202px);
				}
				#location_tags {
					height: 228px; /* in case calc isn't supported */
					height: calc(100vh - 472px);
				}
			}
		}
	}
}