@import "_mixins.scss";

.social-media-signins {
	padding-top: 40px;
	padding-bottom: 70px;
	padding-left: 80px;
	border-left: 1px solid #bbb;
	
	> a {
		display: flex;
		height: 40px;
		width: 347px;
		padding-top: 1px;
		margin-top: 25px;
		border-radius: 3px;

		&:hover {
			text-decoration: none;
		}
		
		> i {
			flex-grow: 0;
			flex-shrink: 0;
			text-align: center;
			font-size: 25px;
			width: 53px;
			padding-top: 11px;
			color: #fff;
			display: inline-block;
		}
		
		> div {
			flex-grow: 1;
			padding-top: 8px;
			font-size: 16px;
			background-color: #fff;
			text-decoration: none;
			text-align: center;
			height: 38px;
			border-top-right-radius: 2px;
			border-bottom-right-radius: 2px;
		}
		
		&.facebook {
			@include social-media-large-button(#3b5998);
		}
		
		&.google-plus {
			@include social-media-large-button(#ea4335);
		}
	}
}

@media screen and (max-width: 250px) {
	html > body div.social-media-signins {
		padding-top: 10px;
		margin-bottom: 5px;
		
		> a {
			margin-top: 15px;
		}
	}
}

@media screen and (max-width: 350px) {
	html > body {
		div.social-media-signins {
			width: 90vw;
			margin-bottom: 15px;

			> a {
				width: 85vw;

				i {
					font-size: 8vw;
					width: 17vw;
				}
				
				> div {
					width: 65vw;
					padding-top: 0;
					line-height: 38px;
					font-size: 5vw;
				}
			}
		}
	}
}

@media screen and (min-width: 350px) and (max-width: 450px) {
	html > body {

		div.social-media-signins {
			width: 305px;
			> a {
				width: 300px;
				
				> div {
					width: 242px;
				}
			}
		}
	}
}

@media only screen and (max-width: 991px) {
	body {
		.social-media-signins {
			width: 350px;
			padding-left: 0;
			padding-bottom: 0;
			border-left-width: 0;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 40px;
		}
	}
}
