@import "_placeholders.scss";

.spreadsheet.sort-by-name .column-titles .name a {
	@extend %sorted-column-title;
}

.spreadsheet.sort-by-rating .column-titles .accessibility-rating a {
	@extend %sorted-column-title;
}

.spreadsheet.sort-by-distance .column-titles .distance a {
	@extend %sorted-column-title;
}

.spreadsheet {
	h3 {
		margin-top: 5px;

		> a {
			color: #000;
		}
	}

	.column-titles a {
		text-decoration: underline;
		transition: color 0.3s;

		&:hover {
			color: #029;
		}
	}

	&.sort-by-name {
		@include solid-column-background(2, 3);
	}

	&.sort-by-rating {
		@include solid-column-background(1, 3);
	}

	&.sort-by-distance {
		@include solid-column-background(1, 2);
	}

	.data {
		max-height: calc(100vh - 190px);
		overflow-y: auto;
		overflow-x: hidden;

		> div {
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			transition: color 0.3s, border-color 0.3s, background-color 0.3s;

			&:nth-child(odd) {
				background-color: rgba(0, 0, 0, 0.05);
			}

			&:hover {
				border-color: rgba(0, 0, 0, 0.3);

				a {
					color: #004;
				}
			}
		}
	}
}

.location-search {
	.accessibility-rating {
		padding-right: 0;
	}

	.spreadsheet {
		.data {
			background-image: url('../images/vertical_arrows.png');
		}
	}

	.no-locations-found {
		text-align: center;
	}
}

@media screen and (max-width: 220px) {
	html > body {
		.location-search {
			.input-group {
				.input-group-addon {
					padding-left: 3px;
					padding-right: 3px;
				}

				input {
					padding: 3px;
					text-align: right;
				}
			}
		}
	}
}

@media screen and (min-width: 275px) and (max-width: 767px) {
	html > body {

		.location-search {
			.form-inline {
				display: flex;

				.form-group {
					flex-grow: 1;
				}

				button {
					flex-shrink: 0;
					flex-grow: 0;
					padding-top: 0;
					padding-bottom: 0;
					height: 34px;
					margin-left: 3px;
				}
			}

			.hide-for-small-viewports {
				display: none;
			}
			.no-locations-found{
				text-align: center;
				h3{
					font-size: 3.8vw;
				}
			}
		}
	}
}

@media screen and (min-width: 100px) and (max-width: 275px) {
	html > body {

		div.location-search {
			h3 {
				margin-bottom: 2px;
			}

			.form-inline {
				text-align: center;

				.form-group {
					margin-bottom: 3px;
				}
			}

			.hide-for-small-viewports {
				display: none;
			}

			.percentage {
				display: none;
			}
		}
	}
}

@media screen and (max-width: 450px) {
	html > body {
		div.location-search {
			h3 {
				font-size: 0.76em;
			}
		}
	}
}

@media screen and (max-width: 550px) {
	html > body {
		.location-search {
			h3 {
				font-size: 0.8em;
				font-weight: bold;
			}

			.data {
				max-height: calc(100vh - 240px);
			}

			&.warned .data {
				max-height: calc(100vh - 250px);
			}
		}
	}
}

@media only screen and (min-width: 400px) and (max-width: 750px) {
	body {
		.location-search {
			h3 {
				font-size: 1.5em;
			}
		}
	}
}

@media screen and (min-width: 751px) and (max-width: 991px) {
	body {
		.location-search {
			h3 {
				font-size: 1.6em;
			}
		}
	}
}

@media only screen and (min-width: 767px) and (max-width: 992px) {
	html > body {
		.location-search {
			h3 {
				font-size: 19px; /* prevent wrapping of text in the Accessibility Rating (%) column */
			}
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
	html > body {
		.location-search {
			.percentage {
				display: none;
			}
		}
	}
}

@media only screen and (min-width: 768px) {
	html > body {
		.location-search {
			.no-locations-found {
				margin-top: 50px;
			}
		}
	}
}

@media only screen and (max-width: 991px) {
	body {
		.location-search {
			&.warned .data {
				max-height: calc(100vh - 215px);
			}

			.data {
				max-height: calc(100vh - 225px);

				.location {
					[class^='col-xs-'] {
						color: #144d7e;
						font-size: 1.05em;
					}

					&:hover {
						[class^='col-xs-'] {
							color: #000;
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.location-search {
		.form-container {
			text-align: center;
		}
	}
}

@media only screen and (min-width: 992px) {
	.location-search {
		&.warned .data {
			max-height: calc(100vh - 225px);
		}
		
		.warning {
			font-size: 16px;
			margin-bottom: 5px;
			text-align: left;
		}

		.disform {
			.input-group > input.form-control {
				width: 250px;
			}
		}
	}
}
