@import "_variables.scss";

@mixin recaptcha-scale($scale) {
	#rc-imageselect, .g-recaptcha {
		transform: scale($scale);
		-webkit-transform: scale($scale);
	}
}

@mixin location-report-box($font_size, $height) {

	.questions-box {
		max-width: ( $height * 0.95 )+px;
		
		.title-bar {
			height: ( $height * 0.15 )+px;

			h3 {
				margin-top: ($font_size / 3)+px;
				margin-bottom: ($font_size / 3)+px;
				font-size: $font_size+px;
			}

			.location-rating {
				.pie-graph.big {
					canvas {
						height: ($font_size * 2.5)+px;
						width: ($font_size * 2.5)+px;
					}
				}

				.foreground .accessible-label {
					font-size: ($font_size / 2)+px;
				}
				
				.percentage {
					font-size: $font_size+px;
				}
			}
		}
		
		.question-category {
			.pie-graph {
				canvas {
					height: ($font_size * 2)+px;
					width: ($font_size * 2)+px;
				}
			}
		
			a {
				padding-top: ($font_size * 0.1)+px;
				padding-bottom: 0;
				
				.category-name {
					font-size: ($font_size * 0.9)+px;
					padding-top: ($font_size * 0.6)+px;
				}
				
				.percentage {
					 @if $font_size < 10 {
					padding-top: 0;
					 }
					 @else {
					padding-top: ($font_size * 0.4)+px;
					}
					font-size: $font_size+px;
				}
			}
		}
	}
}

@mixin social-media-large-button($colour) {
	background-color: $colour;
	border-color: $colour;
	transition: background-color 0.2s, border-color 0.2s;
	
	> div {
		color: $colour;
		border-right-style: solid;
		border-right-width: 1px;
		transition: color 0.2s;
	}
			
	&:hover, &:focus {
		background-color: darken($colour, 20%);
		border-color: darken($colour, 20%);

		> div {
			color: darken($colour, 20%);
		}
	} 
}

@mixin solid-column-background($col1, $col2) {
	.data > div {
		&:nth-child(odd) .row { 
			> div:nth-child(#{$col1}), > div:nth-child(#{$col2}) {
				background-color: #f3f3f3;
			}
		}

		&:nth-child(even) .row {
			> div:nth-child(#{$col1}), > div:nth-child(#{$col2}) {
				background-color: #fff;
			}
		}
	}
}
