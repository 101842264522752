@import "_placeholders.scss";

.question-explanation {
	@extend %page-base;

	h1 {
		text-align: center;
		&.title {
			padding-left: 10px;
		}
	}

	h3 {
		text-align: center;
	}

	.question-html {
		margin-top: 20px;
		margin-bottom: 30px;
		font-size: 1.3em;
		text-align: center;
	}

	.question-explanation {
		max-width: 700px;
		margin-left: auto;
		margin-right: auto;
	}

	.close-row {
		margin-top: 30px;
		border-top: 1px solid #ddd;
		padding-top: 20px;
		text-align: center;
	}
}

@media screen and (max-width: 767px) {
	.question-explanation {
		padding-top: 10px;
	}
}

@media screen and (max-width: 400px) {
	.question-explanation {
		h1.title {
			font-size: 9vw;
			padding-left: 0;
		}

		h3 {
			font-size: 7vw;
		}
	}
}