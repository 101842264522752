/*
This is where to put SCSS for the location report that shows a map.

*/
@import "_placeholders.scss";
@import "_mixins.scss";

.pie-graph {
	display: inline-block;

	canvas {
		height: 40px;
		width: 40px;
	}

	&.big {
		float: right;

		canvas {
			height: 42px;
			width: 42px;
		}
	}
}

.show-ratings-popup {
	.modal-backdrop {
		opacity: 0.5;
	}

	.location-report {
		.map-and-box {
			.questions-box {
				display: block;
			}
		}
	}
}

.highlight {
	background-color: rgba(77, 121, 255,0.3) !important;
}

.suggestion-form {
	label {
		margin-top: 7px;
	}

	.input-group {
		width: 100%;
	}
}

.location-report {
	@extend %page-base;
	a.modify-location, a.find-duplicate-location {
		@extend %selectable-link;

	}
	
	&.location-destroyed {
		> .basic-info {
			> div {
				&.addresses {
					address {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.title {
		display: flex;

		.search-and-title {
			flex-grow: 1;
			display: flex;
			width: 100%;

			a {
				font-size: 28px;
				flex-grow: 0;
				flex-shrink: 0;
				padding-top: 5px;
				width: 35px;
			}

			h1 {
				flex-grow: 1;
				line-height: 40px;
				vertical-align: middle;
			}
		}

		.universal-personal {
			width: 215px;
			white-space: nowrap;
			flex-grow: 0;
			flex-shrink: 0;
		}
	}

	> .basic-info {
		display: flex;
		width: 100%;

		> div {
			&.addresses {
				flex-grow: 8;
				overflow-x: hidden;
				
				address {
					margin-bottom: 4px;
				}

				a {
					display: inline-block;
					text-overflow: ellipsis;
					overflow: hidden;
					height: 1.2em;
					white-space: nowrap;
					max-width: 100%;
				}

				em {
					margin-right: 5px;
				}

				p {
					margin-bottom: 0;
				}

				.destroyed a {
					color: #800;
					font-weight: bold;
				}
			}

			&.location-tags {
				flex-grow: 1;
			}
		}
	}
	
	.images-button {
		display: none;
		&.has-images {
			display: block;
			a {
				@extend %dark-link;

				cursor: pointer;
				display: inline-block;
				margin-bottom: 1px;
				border: 1px solid rgba($base_colour1, 0.3);
				padding: 6px 13px;
				background-color: rgba($base_colour1, 0.75);
				
				&:hover {
					background-color: rgba($base_colour1, 1.0);
				}

				span {
					font-size: 1.5em;
				}
			}
		}
	}

	.location-tags {
		padding: 0;

		.icon {
			display: none;
		}

		.location-tag {
			@extend %dark-link;

			display: inline-block;
			margin-bottom: 1px;
			border: 1px solid rgba($base_colour1, 0.3);
			padding: 6px 13px;
			background-color: rgba($base_colour1, 0.75);

			&:hover {
				background-color: rgba($base_colour1, 0.85);
			}
		}
	}

	&.show-location-tag-icons {
		.location-tag {
			.icon {
				display: block;
				font-size: 30px;
			}

			.name {
				display: none;
			}
		}
	}

	.modal-dialog {
		top: 100px;
	}

	.map-and-box {
		position: relative;
		min-height: 200px;

		#map {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
		}

		.questions-box {
			display: none;
			position: absolute;
			width: 350px;
			top: 0;
			right: 0;
			padding: 5px;
			background-color: #f8f8ff;
			z-index: 1;
			border: 1px solid mix($base_colour1, #ffffff, 70%);
			box-shadow: 0 0 8px #aaa inset, 0 0 8px rgba(0, 0, 0, 0.6);
			border-radius: 4px;
			max-width: 350px;

			&.rated {
				.title-bar {
					display: flex;
					
					.location-name-and-comments {
						flex-grow: 1;
						display: flex;
						flex-direction: column;
						
						.location-name {
							.long-name {
								font-size: 0.9em;
							}
						}
						
						.comments {
							flex-grow: 1;
							position: relative;
						
							a {
								position: absolute;
								bottom: 0;
								width: 100%;
								display: block;
								text-align: center;
								color: #000;
							}
						}
					}

					.location-rating {
						width: 112px;
						flex-shrink: 0;
						flex-grow: 0;
					
						.percentage {
							color: #000;
							font-size: 18px;
							
							.accessible {
								font-size: 0.8em;
							}
						}

						.num-ratings {
							text-align: center;
							color: #000;
							font-size: 10px;
							line-height: 11px;
						}
					}
				}
				
				.question-category {
					> a {
						display: flex;
						width: 100%;
						
						> .graph {
							width: 40px;
							flex-shrink: 0;
							flex-grow: 0;
						}
						
						> .category-name {
							flex-grow: 1;
						}
						
						> .location-category-rating {
							width: 110px;
							flex-shrink: 0;
							flex-grow: 0;
							
							.ratings {
								font-size: 12px;
							}
						}
					}
				}
			}
			
			&.unrated {
				.title-bar {
					padding-top: 15px;
					h3 {
						text-align: center;
						display: block;
						margin-top: 0;
						margin-bottom: 0;
					}
				}

				.questions {
					padding-bottom: 20px;

					p {
						text-align: center;

						a {
							text-decoration: underline;

							&:hover {
								text-decoration: none;
							}
						}
					}
				}
			}

			> div.text-center {
				color: #222;
				cursor: pointer;

				&:hover {
					background-color: #fff;
					color: #000;
					box-shadow:  0px 3px 3px #ddd inset, 0px -3px 3px #ddd inset;
				}
			}

			p {
				color: #222;

				a {
					color: #003;
				}
			}

			.title-bar {
				padding-bottom: 3px;
				height: 48px;

				h3 {
					color: #000;
					max-width: 250px;
					font-size: 16px;
					vertical-align: middle;
					display: table-cell;
				}

				.location-rating {
					position: relative;
					display: table-cell;
					vertical-align: top;

					* {
						text-shadow: 0 0 5px #fff, 0 0 5px #fff, 0 0 5px #fff;
					}
				}
			}

			.question-category {
				a {
					border-radius: 5px;
					border-width: 1px;
					border-style: solid;
					border-color: #888;
					margin-bottom: 4px;
					color: #000;
					padding: 5px 2px;
					display: block;
					text-decoration: none;

					&:hover {
						box-shadow: inset 25px 0px 25px -25px #f8f8f8, inset -25px 0px 25px -25px #f8f8f8;
						box-shadow:  0px 3px 3px #ddd inset, 0px -3px 3px #ddd inset;
						background-color: #fff;
					}
				}
			}
		}
	}

	.question-category {
		.title-bar {
			.pie-graph {
				display: table-cell;
				padding-right: 10px;
			}

			.question-category-name {
				display: table-cell;
				vertical-align: middle;
				font-size: 18px;
				font-weight: bold;
			}
		}
	}

	.title {
		position: relative;
		height: 50px;
		margin-bottom: 3px;
		box-shadow: 0 -6px 2px -2px rgba($base_colour1, 0.1) inset;

		h1 {
			margin: 0 0 0 0;
			padding: 0;
		}

		.universal-personal {
			position: absolute;
			right: 0;
			bottom: 8px;

			a {
				@extend %selectable-link;
			}
		}
	}
}


@media screen and (max-height: 630px) and (min-height: 480px) {
	body {
		.location-report .map-and-box .questions-box {
			@include location-report-box(13, 290);
		}
	}
}

@media only screen and (min-width: 1200px) {
	body {
		.location-report {
			h1 {
				&.longer-than-40 {
					font-size: 32px;

					&.longer-than-60 {
						font-size: 25px;

						&.longer-than-80 {
							font-size: 18px;
						}
					}
				}
			}
			.map-and-box {
				.questions-box {
					width: 400px;
					
					&.unrated h3 {
						max-width: 100%;
					}

					h3 {
						max-width: 300px;

						&.longer-than-60 {
							font-size: 12px;

							&.longer-than-80 {
								font-size: 10px;
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	body {
		.location-report {
			h1 {
				&.longer-than-40 {
					font-size: 28px;

					&.longer-than-60 {
						font-size: 20px;

						&.longer-than-80 {
							font-size: 14px;
						}
					}
				}
			}

			.map-and-box {
				.questions-box {
					width: 400px;
					
					&.unrated h3 {
						max-width: 100%;
					}
					
					h3 {
						max-width: 300px;

						&.longer-than-40 {
							font-size: 14px;

							&.longer-than-60 {
								font-size: 12px;

								&.longer-than-80 {
									font-size: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1199px) {
	body {
		.location-report {
			.title {
				position: static;
				height: auto;
				padding-bottom: 4px;

				h1 {
					position: static;
				}

				.universal-personal {
					position: static;
					padding-top: 8px;
				}
			}
		}

	}
}

@media screen and (max-width: 991px) {
	html > body .location-report > div.map-and-box {
		position: static;

		div.questions-box {
			position: static;
			width: 100%;
			max-width: 100%;

			&.unrated .title-bar h3 {
				width: 100%;
			}

			.title-bar {
				width: 100%;

				h3 {
					width: 80%; /* for browsers that don't support calc or vw */
					max-width: 80%;
					width: calc(100vw - 45px);
					max-width: calc(100vw - 45px);
				}

				> .location-rating {
					width: 113px;
					flex-grow: 0;
					flex-shrink: 0;
				}
			}
		}

		#map {
			position: static;
			width: 100%;
			height: 300px; /* in case the device doesn't support vh units */
			height: 100vh;
			min-height: 200px;
		}
	}
}

@media screen and (min-width: 767px) and (max-width: 991px) {
	html > body .location-report {

		.title {
			display: block;

			h1 {
				font-size: 28px;

				&.longer-than-40 {
					font-size: 24px;

					&.longer-than-60 {
						font-size: 18px;

						&.longer-than-80 {
							font-size: 15px;
						}
					}
				}
			}
		}

		.map-and-box .questions-box.rated .title-bar .location-name-and-comments {
			.location-name {
				h3 {
					font-size: 2.9vw;

					&.long-name {
						font-size: 2.1vw;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 400px) and (max-width: 767px) {
	html body .location-report {
		.title {
			display: block;

			a {
				padding-top: 2px;
			}

			.search-and-title h1 {
				font-size: 14px; /* for browsers that don't support vw units */
				font-size: 4.1vw;
				line-height: 16px;
				line-height: 4.2vw;

				&.longer-than-40 {

					&.longer-than-60 {

						&.longer-than-80 {
							font-size: 3vw;
						}
					}
				}
			}
		}
		&.show-location-tag-icons {
			.location-tag {
				padding-left: 7px;
				padding-right: 7px;

				.icon {
					font-size: 5.5vw;
				}
			}
		}

		.map-and-box .questions-box.rated .title-bar .location-name-and-comments {
			.location-name {
				h3 {
					font-size: 3.5vw;

					&.long-name {
						font-size: 2.5vw;
					}
				}
			}
		}
	}
}

@media screen and (max-height: 532px) {
	body {
		.location-report {
			.modal-dialog {
				top: 0;
				margin-top: 0;
			}
		}
	}
}

@media screen and (max-height: 479px) and (min-height: 380px) {
	body {
		.location-report .map-and-box .questions-box {
			@include location-report-box(11, 253);
		}
	}
}

@media screen and (max-height: 379px) {
	body {
		.location-report {
			.map-and-box .questions-box {
				@include location-report-box(9, 222);
			}
			> div.map-and-box > div.questions-box {		
				.title-bar > .location-name-and-comments > .location-name h3 {
					font-size: 3vw;
				}
			}
		}
	}
}

@media screen and (min-width: 200px) and (max-width: 400px) {
	html body .location-report {
		padding-left: 0;
		padding-right: 0;

		.title {
			display: block;

			a {
				padding-top: 0;
			}

			.search-and-title h1 {
				font-size: 12px; /* for browsers that don't support vw units */
				font-size: 4vw;
				line-height: 4vw;

				&.longer-than-40 {

					&.longer-than-60 {

						&.longer-than-80 {
							font-size: 3vw;
						}
					}
				}
			}
		}

		h3 {
			&.longer-than-40 {
				font-size: 12px;

				&.longer-than-60 {
					font-size: 10px;

					&.longer-than-80 {
						font-size: 8px;
					}
				}
			}
		}
		
		> div.map-and-box div.questions-box {		
			&.rated > .title-bar > .location-rating {
				width: 95px;
				
				.percentage {
					> span.accessible {
						font-size: 0.6em;
					}
				}
			}
		}

		&.show-location-tag-icons {
			.location-tag {
				padding-left: 5px;
				padding-right: 5px;

				.icon {
					font-size: 4vw;
				}
			}
		}
	}
}
