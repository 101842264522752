@import "_placeholders.scss";

.location-management {
	@extend %page-base;

	input[type="text"], select {
		width: 100%;
		box-sizing: border-box;
	}

	form > .row > div {
		min-height: 40px;
	}

	.categories {
		label {
			margin-bottom: 0;
			cursor: pointer;
		}
	}
}
