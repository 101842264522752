// Styles for the signup page

@import "_placeholders.scss";

.sign-up {
	@extend %page-base;
	
	> h1 {
		text-align: center;
		margin-bottom: 40px;
	}
	
	> .header {
		margin-bottom: 30px;

		> h1 {
			margin-top: 0;
			padding-top: 15px;
		}
	}
	
	.success {
		font-size: 1.5em;
	}
	
	.agreements {
		margin-top: 70px;
		margin-left: auto;
		margin-right: auto;
		line-height: 25px;
		color: #666;
		width: 350px;
	}

	.g-recaptcha {
		margin-top: 15px;
	}
}

.signup-form {
	width: 400px;
	margin-left: auto;
	margin-right: auto;
}

@media screen and (max-width: 300px) {
	html > body {
		.sign-up {
			.agreements {
				margin-top: 15px;
				margin-bottom: 20px;
			}
			
			.header {
				margin-bottom: 15px;
			}
		}
	}
}

@media screen and (max-width: 450px) {
	html > body {
		.signup-form {
			width: 100%;
		}

		.sign-up .agreements {
			width: 100%;
		}
	}
}

@media only screen and (max-width: 991px) {
	body {
		
		.sign-up {
			.agreements {
				margin-bottom: 50px;
			}
		}
	}
}
