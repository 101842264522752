.map-location-visualizer {
	@extend %page-base;

	#map {
		height: calc(100vh - 150px);
		min-height: 400px;
	}
	
	textarea {
		display: block;
		width: 100%;
		height: 500px;
	}
}