.slideshow {
	position: fixed;
	cursor: pointer;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 2;

	.delete {
		display: none;
	}

	&.can-be-deleted {
		.delete {
			display: block;

			em {
				color: #444;
			}

			&:hover {
				em {
					color: #000;
				}
			}
		}
	}
	
	.slideshow-dialog {
		position: absolute;
		top: 50%;
		left: 50%;
		background-color: #fff;
		border-radius: 5px;
		text-align: center;
		z-index: 2;
		box-shadow: 0 0 15px #000;
		
		.next, .previous, .delete {
			position: absolute;
			bottom: 0;
		}

		.next, .previous {
			background-repeat: no-repeat;
			background-position: center center;
			top: 0;
			width: 50%;
			transition: opacity 0.3s;
			cursor: pointer;
			text-decoration: none;
			color: #222;
			opacity: 0.7;

			&:hover {
				opacity: 1.0;
			}
		}

		.next {
			background-image: url('../images/slideshow/arrow-right-128px.png');
			/* Try SVG in browsers that support it. */
			background-image: url('../images/slideshow/arrow-right.svg'), linear-gradient(transparent, transparent);
			right: 0;
			background-position: center right;
		}

		.previous {
			background-image: url('../images/slideshow/arrow-left-128px.png');
			/* Try SVG in browsers that support it. */
			background-image: url('../images/slideshow/arrow-left.svg'), linear-gradient(transparent, transparent);
			left: 0;
			background-position: center left;
		}

		.delete {
			left: 0;
			right: 0;
		}

		.image {
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			height: 100%;
		}
	}
}

@media (min-width: 2000px) {
	.slideshow {
		.slideshow-dialog {
			margin-top: -30vh;
			margin-left: -35vw;
			height: 60vh;
			width: 70vw;
			padding-top: 40px;
			padding-bottom: 40px;
			padding-left: 20px;
			padding-right: 20px;
			
			.next, .previous {
				background-size: 70px auto;
				border-radius: 10px;
			}
			
			.previous {
				background-position: 20px center;
			}

			.next {
				background-position: right 20px center;
			}

			.delete {
				font-size: 32px;
			}
		}
	}
}

@media (min-width: 800px) and (max-width: 2000px) {
	.slideshow {
		.slideshow-dialog {
			margin-top: -35vh;
			margin-left: -35vw;
			height: 70vh;
			width: 70vw;
			padding-top: 30px;
			padding-bottom: 30px;
			padding-left: 10px;
			padding-right: 10px;

			.next, .previous {
				background-size: 50px auto;
				padding-left: 4px;
				padding-right: 4px;
				border-radius: 5px;
			}
			
			.previous {
				background-position: 10px center;
			}

			.next {
				background-position: right 10px center;
			}

			.delete {
				font-size: 22px;
			}
		}
	}
}

@media (max-width: 800px) {
	.slideshow {
		.slideshow-dialog {
			width: 100%;
			left: 0;
			border-radius: 0;
			margin-top: -40vh;
			height: 80vh;
			padding-top: 15px;
			padding-bottom: 25px;

			.next, .previous {
				background-size: 40px auto;
				padding-right: 2px;
				padding-left: 2px;
				border-radius: 3px;
			}

			.previous {
				background-position: 5px center;
			}

			.next {
				background-position: right 5px center;
			}

			.delete {
				padding-top: 20px;
				font-size: 18px;
			}
		}
	}
}