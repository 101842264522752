// Styles for the contact page

@import "_placeholders.scss";

.contact {
	@extend %page-base;
	
	h1 {
		padding-top: 20px;
		text-align: center;
		margin-bottom: 20px;
	}
	
	.contact-form {
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		
		textarea {
			resize: vertical;
		}
	}
	
	.sent-message {
		font-size: 1.5em;
		
		strong {
			line-height: 70px;
			
			.sent {
				font-size: 1.3em;
			}
		}
	}

	input[type="submit"] {
		margin-bottom: 30px;
	}
}
