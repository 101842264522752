@import "_placeholders.scss";

.suggestion-list {
    @extend %page-base;
    @extend %alternative-row-data;

    h1 {
        margin-top: 0;
    }

    a {
        text-decoration: none !important;
    }

	.list-group {
		a.list-group-item {
			&:nth-child(odd) {
				background-color: #eee;
			}

			.when-generated {
				float: right;
			}   
		}
	}
}

@media (min-width: 800px) {
	.suggestion-list {
		.list-group {
			a.list-group-item {
				padding-right: 20px;

				.username {
					padding-left: 5px;
				}

				.location-name {
					padding-left: 30px;
				}
			}
		}
	}
}

@media (min-width: 600px) and (max-width: 800px) {
	.suggestion-list {
		.list-group {
			a.list-group-item {
				padding-right: 15px;

				.username {
					padding-left: 3px;
				}

				.location-name {
					padding-left: 15px;
				}
			}
		}
	}
}

@media (max-width: 600px) {
	.suggestion-list {
		.list-group {
			a.list-group-item {
				padding-right: 5px;

				.submitted-by {
					display: none;
				}

				.location-name {
					padding-left: 20px;
				}
			}
		}
	}
}
