
.nav-profile.profile-names .profile-names-page {
	@extend %page-base;
	@extend %dialog-page;

	h1 {
		padding-top: 20px;
		text-align: center;
		margin-top: 0;
		margin-bottom: 20px;
	}

	form {
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
		
		.password {
			margin-top: 7px;
		}
	}
}

@media screen and (min-width: 1000px) {
	.nav-profile.profile-names .profile-names-page {
		h1 {
			margin-bottom: 90px;
		}

		form {
				max-width: 500px;

				.footer {
					margin-top: 30px;
				}
		}
	}
}