@import "_add_location.scss";
@import "_clean.scss";
@import "_contact.scss";
@import "_dialogs.scss";
@import "_error_pages.scss";
@import "_faq.scss";
@import "_functions.scss";
@import "_home.scss";
@import "_duplicate_location_finder.scss";
@import "_locations_added_by_me.scss";
@import "_location_event.scss";
@import "_location_management.scss";
@import "_location_created.scss";
@import "_internal.scss";
@import "_location_map_report.scss";
@import "_location_report_ratings_only.scss";
@import "_location_question_category_view_and_rating.scss";
@import "_location_search.scss";
@import "_location_search_table.scss";
@import "_location_slideshow.scss";
@import "_location_tagging.scss";
@import "_map_location_visualizer.scss";
@import "_menu.scss";
@import "_mixins.scss";
@import "_placeholders.scss";
@import "_privacy_policy.scss";
@import "_profile.scss";
@import "_profile_home_address.scss";
@import "_profile_names.scss";
@import "_question_explanation.scss";
@import "_question_explanation_link.scss";
@import "_recaptcha_scaling.scss";
@import "_reviewed_locations.scss";
@import "_sign_in.scss";
@import "_sign_up.scss";
@import "_social_media_buttons.scss";
@import "_suggestion_list.scss";
@import "_suggestion_detail.scss";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

html, body {
	background-color: #e7f3f5;
	min-height: 100%;
}

html body {
	font-family: arial;
}
html {
	position: relative;
}

body {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow-x: hidden;
}

h1, h2, h3 {
	font-family: arial;
}

.warning {
	color: #600;
	font-weight: bold;
}

#map {
	background-color: #ddd;
}

.content {
	text-align: center;
}

#main {
	min-height: 200px;
	> h1 {
		margin-top: 0;
		margin-left: 10px;
	}
}

#copyright {
	display: block;
	left: 0;
	right: 0;
	padding-left: 15px;
	border-top: 1px solid #ddd;
	background-color: rgba(255, 255, 255, 0.8);
}

footer {
	position: absolute;
	margin-top: 30px;
	left: 0;
	right: 0;
	bottom: 0; 
}

.container {
	background-color: #fff;
	position: relative;
	min-height: 100%;
	box-shadow: 0 0 15px #888;
}

.box {
	padding: 10px 15px;
	background-color: #eef;
	border: 1px solid #aaa;
	box-shadow: 0 0 4px #ccc inset;
	border-radius: 3px;
	
	.form-group {
		margin-bottom: 7px;
		
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.password-recovery {
	@extend %page-base;
	
	.form-box {
		margin: auto;
		text-align: center;
		max-width: 500px;
		
		input[type="email"] {
			margin-bottom: 20px;
		}
	}
}

.change-password {
	@extend %page-base;

	h1 {
		text-align: center;
		padding-top: 30px;
		margin-bottom: 50px;
	}
	
	.password-change-form {
		margin-left: auto;
		margin-right: auto;
		max-width: 500px;
	}
}

.terms-of-use {
	@extend %page-base;
}

.privacy-policy {
	@extend %page-base;
}

@media screen and (max-width: 250px) {
	#main > div {
		padding-left: 5px;
		padding-right: 5px;
	}
}

@media screen and (max-width: 450px) {
	html > body {
		#copyright {
			// keep the copyright notice on a single line.
			// Use em for older browsers not supporting vw units.
			font-size: 0.8em;

			font-size: 3.3vw;
		}
	}
}
