@import "_placeholders.scss";

.location-search {
	@extend %page-base;

	.title-map-table-bar {
		> div.title {
			display: inline-block;
			float: none;
			padding-top: 0;
		}
	
		h1 {
			margin-top: 0;
			margin-bottom: 0;
			white-space: nowrap;
		}
		
		.keyword-filter {
			font-size: 0.9em;
			margin-bottom: 0;
		}

		> div {
			display: inline-block;
			padding-top: 10px;
			float: right;

			> a {
				@extend %selectable-link;
			}
		}
	}

	#map {
		height: 200px;
		height: calc(100vh - 130px);
		background-color: #888;
	}

	&.warned .data {
		max-height: calc(100vh - 230px);
	}

	.warning {
		display: block;
		text-align: center;
		line-height: 1.1;
	}
}

@media screen and (max-width: 300px) {
	html > body {
		div.location-search {
			.title-map-table-bar {
				h1 {
					display: block;
					text-align: center;
					font-size: 1.2em;
					font-size: 6.5vw;

					.location-search-results-for {
						display: block;
						text-align: center;
					}
				}
			}

			.warning {
				font-size: 5vw;
			}
		}
	}
}

@media screen and (min-width: 300px) and (max-width: 767px) {
	html > body {
		div.location-search {
			.title-map-table-bar {
				h1 {
					font-size: 1.2em;
					font-size: 3.8vw;
				}
			}
		}
	}
}

@media screen and (max-width: 551px) {
	html > body {
		div.location-search {
			padding-left: 2px;
			padding-right: 2px;
		}
	}
}

@media screen and (min-width: 751px) and (max-width: 991px) {
	body {
		.location-search {
			h1 {
				font-size: 1.9em;
			}
		}
	}
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	html > body {
		.location-search {
			h1 {
				font-size: 33px;
			}
		}
	}
}

@media only screen and (max-width: 991px) {
	body {
		.location-search {
			h1 {
				margin-bottom: 0;
			}

			div.title-map-table-bar {
				> div {
					float: none;
					clear: both;
					display: block;
					text-align: center;
					margin-bottom: 5px;
				}
			}
		}
	}
}
