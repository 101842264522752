@import "_placeholders.scss";

.duplicate-location-finder {
	@extend %page-base;
	
	h2 {
		margin-top: 3px;
		margin-bottom: 3px;
	}
	
	form {
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		
		div {
			display: flex;

			input[type="number"] {
				width: 100%;
				box-sizing: border-box;
				flex-grow: 1;
			}
			
			label {
				width: 200px;
				flex-shrink: 0;
				flex-grow: 0;
			}
		}
	}

	.spreadsheet {
		.row {
			border-bottom: 1px solid #ddd;
		}
	}
}