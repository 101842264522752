@import "_mixins.scss";

@media screen and (min-width: 100px) and (max-width: 200px) { 
	@include recaptcha-scale(0.5);
}

@media screen and (min-width: 200px) and (max-width: 230px) { 
	@include recaptcha-scale(0.6);
}

@media screen and (min-width: 230px) and (max-width: 268px) { 
	@include recaptcha-scale(0.7);
}

@media screen and (min-width: 268px) and (max-width: 320px) { 
	@include recaptcha-scale(0.77);
}

@media screen and (max-width: 320px) { 
	#rc-imageselect, .g-recaptcha {
		transform-origin: 0 0;
		-webkit-transform-origin:0 0;
	}
}