@import "_placeholders.scss";

.faq {
	@extend %page-base;
	padding-bottom: 25px;

	.faq-items {
		margin-top: 30px;

		> div {
			> p {
				font-size: 1.2em;
				font-weight: bold;
			}
			
			> .answer {
				padding-left: 25px;
				
				ol {
					margin-bottom: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {
	html > body {
		.faq h1 {
			font-size: 7vw;
		}
	}
}

@media screen and (max-width: 300px) {
	html > body {
		.faq {
			.faq-items {
				> div > .answer {
					padding-left: 10px;
				}
			}
		}
	}
}
