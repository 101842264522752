@import "_placeholders.scss";
@import "_mixins.scss";


.location-reporting {
	.menu h1 {
		@extend %location-menu-heading;
	}

	.questions {
		> div {
			padding-bottom: 10px;
			padding-top: 5px;
			border-bottom: 1px solid #ddd;
			display: flex;
			width: 100%;
			
			.question-html {
				flex-grow: 1;
			}

			.stats {
				flex-grow: 0;
				flex-shrink: 0;
				display: flex;
				flex-direction: column;
				width: 65px;

				.percentage {
				}
			}
		}
	}

	div.comments {
		> h2 {
			margin-bottom: 10px;
		}

		> div {
			margin-left: 5px;
			margin-top: 5px;

			&.comment {
				padding: 5px 8px;
				border: 1px solid #aaa;
				border-radius: 3px;
				box-shadow: 0 0 5px #ccc inset;

				.comment-header {
					font-weight: bold;
					margin-bottom: 5px;
				}

				.comment-content {
					font-size: 1.2em;
				}
			}
		}
	}
}

.location-comments {
	margin-left: 10px;
	margin-right: 10px;

	div.comments {
		> h2 {
			margin-bottom: 10px;
		}

		.category-subheader {
			font-size : 1.5em;
			font-weight: bold;
			margin-bottom: 5px;
			text-align: center;
		}
		
		> div {
			margin-left: 5px;
			margin-top: 5px;


			&.comment {
				padding: 5px 8px;
				border: 1px solid #aaa;
				border-radius: 3px;
				box-shadow: 0 0 5px #ccc inset;



				.comment-header {
					font-weight: bold;
					margin-bottom: 5px;
				}

				.comment-content {
					font-size: 1.2em;
				}
			}
		}
	}
}

.location-rating {
	.menu h1 {
		@extend %location-menu-heading;
	}
	.collapsible-menu {
		.submit {
			display: inline-block;
		}
	}
}

.location-rating, .location-reporting {
	.collapsible-menu {
		display: none;

		a {
			@extend %selectable-question-category;
		}
	}

	.rate-report {
		display: inline-block;
	}

	.collapse-toggle-button {
		display: none;
	}
}

// Used in smart_menu.js for locking
// the left menu location.
body.fixed-menu {
	.location-rating, .location-reporting {
		display: block;

		.menu {
			position: fixed;
			top: 0;
		}

		.rate, .ratings {
			margin-left: 230px;
		}
	}
}

#main > .location-rating, #main > .location-reporting {
	@extend %page-base;

	display: flex;
	margin-bottom: 100px;

	h1 {
		margin-top: 5px;
		margin-left: 5px;
	}

	h2 {
		margin-top: 5px;
		margin-left: 5px;
		margin-bottom: 0;
	}

	.menu {
		width: 230px;
		flex-shrink: 0;

		.submit {
			margin-top: 30px;
			text-align: center;

			form {
				display: inline-block;
			}

			.clean {
				margin: auto;
			}
		}

		.question-categories {
			margin-top: 5px;
			padding-left: 5px;
			padding-right: 10px;

			a {
				@extend %selectable-question-category;
			}
		}
	}

	.rate, .ratings {
		margin-bottom: 50px;
		flex-grow: 1;

		h1 {
			display: inline;
		}

		.rate-report-toggle {
			display: inline-block;
			float: right;
			margin-top: 10px;

			.rate-report {
				a {
					@extend %selectable-link;
				}
			}
		}

		.questions {
			padding-right: 10px;
			padding-left: 15px;

			> .row {
				padding-top: 12px;
				padding-bottom: 12px;
				border-bottom: 1px solid #ddd;

				.answers {
					> div {
						padding: 0 3px;

						&.selected[class] {
							> div {
								background-color: #84e39a;
								border-color: #004;
							}
						}

						&.always-required[class], &.disabled[class] {
							> div {
								background-color: #ddd;
								border-color: #888;
								color: #777;
								cursor: not-allowed;
							}
						}

						> div {
							text-align: center;
							padding-top: 5px;
							padding-bottom: 5px;
							background-color: #c6d8fd;
							border: 1px solid #bbb;
							font-weight: bold;
							border-radius: 2px;
							cursor: pointer;
							transition: background-color 0.2s, border-color 0.2s;

							&:hover {
								border-color: #000;
								background-color: #eee;
							}
						}
					}
				}
			}
		}

		textarea {
			height: 150px;
		}
	}
}

@media screen and (min-width: 1200px) {
	#main > .location-rating, #main > .location-reporting {
		.rate h1 a, .ratings h1 a {
			&.longer-than-60 {
				font-size: 24px;

				&.longer-than-80 {
					font-size: 20px;
				}
			}
		}
	}
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
	#main > .location-rating, #main > .location-reporting {
		.rate h1 a, .ratings h1 a {
			&.longer-than-60 {
				font-size: 21px;
				&.longer-than-80 {
					font-size: 19px;
				}
			}
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 991px) {
	#main > .location-rating, #main > .location-reporting {
		.rate h1 a, .ratings h1 a {
			&.longer-than-60 {
				font-size: 20px;
				&.longer-than-80 {
					font-size: 17px;
				}
			}
		}
	}
}

@media screen and (min-width: 380px) and (max-width: 767px) {
	#main > .location-rating, #main > .location-reporting {
		.rate h1 a, .ratings h1 a {
			&.longer-than-60 {
				font-size: 3.5vw;
				&.longer-than-80 {
					font-size: 3vw;
					line-height: 0;
				}
			}
		}
	}
}

@media screen and (min-width: 340px) and (max-width: 380px) {
	body {
		#main {
			.location-reporting, .location-rating {
				h2 {
					font-size: 25px;
					font-size: 7vw;
				}
			}
		}
	}
}

@media screen and (max-width: 340px) {
	html > body {
		div#main {
			> .location-reporting, > .location-rating {
				.ratings, .rate {
					.rate-report-toggle {
						float: none;
						width: 100%;

						.rate-report {
							right: auto;
							left: 0;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 320px) {
	html > body {
		.location-reporting, .location-rating {

			.answers > div > div {
				vertical-align: middle;
				min-height: 52px;
			}
		}
	}
}

@media screen and (max-width: 379px) {
	html > body {
		.location-reporting, .location-rating {
			.rate h1 a, .ratings h1 a {
				&.longer-than-60 {
					font-size: 4.2vw;
					&.longer-than-80 {
						font-size: 4vw;
					}
				}
			}

			h2 {
				white-space: nowrap;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	body {
		&.fixed-menu {
			#main {
				.location-reporting, .location-rating {
					.rate, .ratings {
						margin-left: 0;
					}

					.collapsible-menu {
						position: fixed;
						top: 0;
					}
				}
			}
		}

		#main {
			.location-reporting, .location-rating {
				padding-right: 0;
				padding-left: 0;

				h1 {
					display: block;
					min-height: 8vw;
					font-size: 7vw;
				}

				.menu {
					display: none;
				}

				.questions {
					margin-right: 5px;
					margin-left: 5px;
				}				
				
				.rate-report-toggle {
					position: relative;
					width: 183px;
					height: 30px;

					.rate-report {
						position: absolute;
						top: 0;
						right: 38px;
					}

					.collapse-toggle-button {
						display: block;
						margin-top: -3px;
						float: right;
						cursor: pointer;

						span {
							font-size: 32px;
							padding-left: 4px;
							padding-right: 4px;
							padding-top: 0;
							margin-top: 0;
							height: 32px;
						}
					}
					
					.collapsible-menu {
						position: absolute;
						top: 34px;
						right: 0;
						display: block;
						text-align: right;
						margin-top: 0;
						transition: background-color 0.2s;

						ul {
							display: none;
						}

						&.show-popup {
							z-index: 1;
							ul {
								padding: 0;
								margin: 0;
								background-color: #fff;
								list-style-type: none;
								display: block;
								box-sizing: border-box;

								li {
									width: 100%;
									width: 100vw;
								}
							}
						}
					}
				}
			}
		}
	}
}
