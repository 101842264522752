// Styles for the top of each and every page including the logo and navigation menu.

.nav-home-page a.nav-home,
.nav-profile a.nav-profile,
.nav-faq a.nav-faq,
.nav-contact a.nav-contact {
	color: #000;
	box-shadow: 0 0 4px #bbb inset;
}

#logo {
	font-size: 26px;
	text-decoration: none;
	background-image: url('../images/logo.png');
	background-image: url('../images/logo.svg'), linear-gradient(transparent, transparent);
	background-repeat: no-repeat;
	background-size: contain;
	display: inline-block;
	height: 40px;
	padding-left: 45px;
}

header {
	padding-left: 10px;
	padding-right: 10px;
}

// horizontal menu at top of every page
ul.nav {
	list-style-type: none;
    margin: 0;
    padding: 0;
	
	li {
		float: left;
	}
	
	.sign-out {
		font-size: 20px;
		line-height: 16px;
	}
}


@media screen and (max-width: 550px) {
	html > body {
		.nav > li > a {
			// Some pixel units for older browsers not supporting vw units
			padding-left: 7px;
			padding-right: 7px;

			// vw units for newer browsers
			padding-left: 2vw;
			padding-right: 2vw;
		}
	}
}

@media screen and (max-width: 250px) {
	html > body {
		#logo {
			font-size: 10vw;
			height: 14vw;
			padding-left: 15vw;
		}

		header {
			padding-left: 5px;
			padding-right: 5px;
			.navbar-inner .nav > li > a {
				font-size: 5.5vw;
			}
		}
	}
}