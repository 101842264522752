@import "_placeholders.scss";

.location-created {
	@extend %page-base;
	
	p {
		margin-top: 50px;
	}

	p, nav {
		font-size: 26px;
	}
	
	.what-to-do-now {
		margin-left: auto;
		margin-right: auto;
		max-width: 350px;
	}
}

@media screen and (min-width: 360px) and (max-width: 500px) {
	.location-created {
		p {
			margin-top: 40px;
		}

		p, nav {
			font-size: 22px;
		}
		
		.what-to-do-now {
			max-width: 300px;
		}
	}
}

@media screen and (min-width: 250px) and (max-width: 360px) {
	.location-created {
		h1 {
			font-size: 9vw;
		}
	
		p {
			margin-top: 20px;
		}

		p, nav {
			font-size: 18px;
		}
		
		.what-to-do-now {
			max-width: 250px;
		}
	}
}

@media screen and (max-width: 250px) {
	.location-created {
		h1 {
			font-size: 9vw;
		}
	
		p {
			margin-top: 10px;
		}

		p, nav {
			font-size: 16px;
		}
		
		.what-to-do-now {
			max-width: 100%;
		}
	}
}