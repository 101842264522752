// Styles for reviewed locations
// Reviewed Locations can be navigated to from the profile page

@import "_placeholders.scss";


.reviewed-locations {
	@extend %page-base;
	@extend %alternative-row-data;

	h1 {
		margin-top: 0;	
		text-align: center;
	}
	
	> p {
		text-align: center;
	}
}
