// Styles for the sign in feature

@import "_placeholders.scss";


.signin-form {
	margin: auto;
	width: 300px;

	.message {
		font-size: 1.3em;
	}
}

.sign-in {
	@extend %page-base;
	
	> div {
		margin-bottom: 20px;

		> h1 {
			margin-top: 0;
			padding-top: 15px;
		}
	}
	.capsLock{
		font-style: italic;
		margin-bottom: 0px;
		margin-top: 3px;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-right: 0px;
		display: none;
	}
	.fa-exclamation-circle {
		margin-left: 10px;
	}

	.remember-password {
		padding-top: 10px;
	}
}

@media screen and (max-width: 320px) {
	body .signin-form {
		width: 100%;
	}
}

@media screen and (min-width: 250px) and (max-width: 290px) {
	body .signin-form {
		label, a {
			font-size: 0.9em;
		}

		a {
			padding-top: 4px;
			display: inline-block;
		}
	}
}

@media screen and (max-width: 250px) {
	body {
		.signin-form {
			label {
				display: block;
			}

			a {
				float: none !important;
			}
		}

		.sign-in {
			> div {
				margin-bottom: 5px;
			}
		}
	}
}