@import "_variables.scss";

%fashionable-link {
	&:hover {
		text-decoration: none;
	}
}

%dark-link {
	@extend %fashionable-link;

	color: #fff;
	font-size: 1.4em;
	box-shadow: 0 0 4px #fff inset;
	border-radius: 2px;
	transition: border-color 0.2s, background-color 0.2s;
}

%selectable-link {
	@extend %dark-link;
	
	padding: 5px 10px;
	background-color: rgba($base_colour1, 0.5);
	
	&:hover {
		background-color: rgba($base_colour1, 0.7);
	}

	&.selected {
		background-color: rgba($base_colour1, 0.95);
	}
}

%selectable-question-category {
	display: block;
	text-align: center;
	font-size: 1.5em;
	padding: 8px;
	border-bottom: 1px solid #ddd;
	transition: color 0.2s, background-color 0.2s;
	
	&:hover {
		color: #002;
		background-color: #c6d8fd;
	}
	
	&.selected {
		color: #001;
		border: 1px solid #888;
		box-shadow: 0 0 5px #888 inset;
	}
}

%page-base {
	padding-left: 10px;
	padding-right: 10px;
	box-shadow: inset 0 7px 9px -7px #ddd;
	
	> h1 {
		margin-top: 0;
	}
}

%sorted-column-title {
	text-decoration: none;
	color: #000;
}

%location-menu-heading {
	font-size: 28px;
	text-align: center;
}

%nonscrolling-spreadsheet {
	border: 1px solid #999;

	.row {
		margin-left: 0;
		margin-right: 0;

		&:nth-child(even) {
			background-color: $dark_data_background_colour;
		}
		&.headings {
			> div {
				font-weight: bold;
				min-height: 45px;
				border-bottom: 1px solid #ccc;
			}
		}
	}
}

%internal-box {
	h2 {
		margin-top: 0;
	}

	ul {
		list-style-type: none;
		margin-left: 0;
		padding-left: 0;

		span {
			font-weight: bold;
		}
	}
}

%photo-replace-remove-link {
	display: block;
	color: #558;
	background-color: #eee;
	box-shadow: 0 0 3px #ccc inset;
	transition: color 0.3s;
	
	&:hover {
		color: #225;
		text-decoration: underline;
	}
}

%clean-submit {
	height: 38px;
	font-size: 18px;
	margin-top: 30px;
	border: 1px solid #337ab7;
	color: #337ab7;
	width: auto;
	padding-left: 40px;
	padding-right: 40px;
	border-radius: 19px;
	background-color: #fff;
	
	&:hover, &:focus {
		border-color: #000;
		color: #000;
	}
}

%alternative-row-data {
	.data {
		padding: 0 5px;
		> div:nth-child(odd) {
			background-color: $dark_data_background_colour;
		}
		
		a {
			display: block;
		}
	}
}

%dialog-page {
	.footer {
		margin-top: 20px;
		
		a {
			text-decoration: none;
		}
	}
}