.nav-profile div.home-address {
    @extend %page-base;
	@extend %dialog-page;

	h1 {
		padding-top: 0;
		margin-top: 0;
		text-align: center;
	}

	p {
		text-align: center;
	}

	form {
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media screen and (min-width: 800px) {
	.nav-profile div.home-address {

		h1 {
			margin-bottom: 50px;
		}
	
		p {
			margin-top: 20px;
			margin-bottom: 30px;
		}

		form {
			.footer {
				margin-top: 40px;
			}
		}
	}
}

@screen and (max-width: 510px) {
	.nav-profile div.home-address {
		form {
			max-width: 100vw;
		}
	}
}