@import "_placeholders.scss";

html > body #main > .home-page {
	padding-left: 0;
	padding-right: 0;
}

.home-page {
	min-height: 94vh;
	min-height: calc(100vh - 40px);
	background-image: url('../images/home_background_1170px.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-color: #818d87;
	color: #fff;
	
	.address-row {
		display: flex;
		height: 34px;
		margin-bottom: 15px;
		
		input {
			flex-grow: 1;
		}
		
		button {
			width: 40px;
			flex-grow: 0;
		}
	}	

	.intro {
		width: 410px;
		padding-left: 78px;
		padding-top: 50px; // For older browsers not supporting viewport units
		padding-top: 12vh;
		padding-right: 45px;
		padding-bottom: 50px;
		margin-right: auto;
		margin-bottom: 50px;
		background-color: rgba($base_colour1, 0.75);
		
		h1 {
			margin-bottom: 20px;
		}
		
		p {
			font-size: 1.15em;
			
			&.slogan {
				font-style: italic;
				white-space: nowrap;
			}
		}
	}

	.home-inputs {
		width: 550px;
		padding-top: 30px;
		margin-left: auto;
		margin-right: auto;
		
		
		.sign-in-sign-up {
			font-weight: bold;
		}

		.search {
			width: 100%;
			margin-top: 30px;

			form, table {
				width: 100%;
				padding: 0;
			}
			
			input {
				width: 100%;
				
				&[name='address'] {
					margin-bottom: 15px;
				}
			}
			
			.search-button {
				width: 40px;
			}
		}
	}
	
	.signin-signup {
		display: flex;
		justify-content: space-between;
		a {			
			@extend %dark-link;
			border: 1px solid rgba($base_colour1, 0.1);
			display: inline-block;
			padding: 16px 20px;
			background-color: rgba($base_colour1, 0.75);
			height: 85px;
			width: 49%;

			table {
				width: 100%;
				height:100%;
				.user-icon {
					width: 33px;
				}
			}
			
			i {
				font-size: 2.3em;
			}
			
			&:hover {
				border-color: #fff;
				background-color: rgba($base_colour1, 0.85);
			}
		}
	}
	
	#map {
		width: 100%;
		height: 210px;
		margin-top: 25px;
	}

	.location-tags {
		padding-right: 15px;
		padding-left: 15px;
		margin-top: 40px;
		margin-bottom: 40px;
		
		.location-tag {
			padding-left: 0;
			padding-right: 0;

			&:nth-last-child(3), &:nth-last-child(2), &:nth-last-child(1) {
				> a {
					border-bottom-width: 0;
				}
			}
			
			&:nth-child(3n + 1) {
				> a {
					border-left-width: 0;
				}
			}

			> a {
				@extend %fashionable-link;
				padding: 5px 4px 0 4px;
				height: 105px;
				display: flex;
				align-items: center;
				text-align: center;
				justify-content: center;
				width: 100%;
				font-weight: bold;
				font-size: 1.3em;
				color: #fff;
				cursor: pointer;
				background-color: rgba($base_colour1, 0.5);
				border-left: 1px solid #fff;
				border-bottom: 1px solid #fff;
				transition: background-color 0.2s, font-size 0.3s;
								
				&:hover {
					background-color: #000;
					font-size: 1.6em;
				}
			}
		}
	}	
}

@media screen and (max-width: 250px) {
	html > body {
		.home-page {
			.home-inputs {
				> .location-tags {
					> div > .location-tag {
						width: 100%;
						
						> a {
							border-left-width: 0;
							border-right-width: 0;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 310px) {
	html > body {
		.home-page {
			.intro {
				h1 {
					font-size: 11.6vw;
					margin-bottom: 3vw;
				}

				p.slogan {
					font-size: 4.29vw;
				}
			}
			.home-inputs {
				> .location-tags {
					a {
						font-size: 5.9vw;

						&:hover {
							font-size: 7.2vw;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 360px) {
	html > body {
		.home-page {
			.intro {
				p {
					font-size: 0.95em;
				}
			}
		}
	}
}

@media screen and (max-width: 550px) {
	html > body {

		.home-page {
			.intro {
				width: 100%;

				// Some pixel units for older browsers not supporting vw units
				padding-left: 30px;
				padding-right: 30px;

				padding-right: 10vw;
				padding-left: 10vw;
			}
			.home-inputs {
				width: 100%;
			}
		}
	}
}

@media screen and (min-width: 1200px) {
	body {
		.home-page {
			background-image: url('../images/home_background_clear_1170px.jpg');
		}
	}
}

@media screen and (min-width: 751px) and (max-width: 970px) {
	body {
		.home-page {
			background-image: url('../images/home_background_970px.jpg');
		}
	}
}

@media only screen and (max-width: 750px) {
	body {
		.home-page {
			background-image: url('../images/home_background_750px.jpg');
		}
	}
}

@media only screen and (max-width: 767px) {
	body {
		.home-page {
			.home-inputs {
				> .location-tags {
					> div > .location-tag {
						> a {
							border-left-width: 1px;
							border-bottom-width: 1px;
						}
						
						&:nth-last-child(2), &:nth-last-child(1) {
							> a {
								border-bottom-width: 0;
							}
						}
						
						&:nth-child(2n + 1) {
							> a {
								border-left-width: 0;
							}
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1199px) {
	body {
		.home-page {
			.intro {
				padding-top: 30px;
				width: 550px;
				margin-left: auto;
				margin-right: auto;
			}
			
			.location-tags {
				margin-bottom: 80px;
			}
		}
	}
}

#hover-text-display {
	position: fixed;
	bottom: 0;
	padding-bottom: 20px; // Position above the URL text that appears when hovering over a link
	width: 1170px;
	font-size: 18px;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.7);
	box-shadow: 0 0 5px #fff;
	box-sizing: border-box;
	opacity: 1;
	transition: opacity 1.0s;
	
	&.hiding {
		opacity: 0;
	}
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
	#hover-text-display {
		width: 970px;
	}
}

@media screen and (max-width: 991px) and (min-width: 768px) {
	#hover-text-display {
		width: 750px;
	}
}

@media screen and (max-width: 767px) {
	#hover-text-display {
		width: 100%;
	}
}

/* The following SCSS is mainly to prevent vertical scrolling and 
keep all 12 location categories visible.  */
@media screen and (min-width: 960px) and (max-height: 700px) {
	.home-page {
		.home-inputs {

			&.signed-out {
				padding-top: 10px;

				.signin-signup {
					a {
						padding-top: 2px;
						padding-bottom: 2px;
						height: 45px;
						
						td {
							font-size: 17px;
						}
						
						i {
							font-size: 1.4em;
						}
					}
				}

				#map {
					margin-top: 8px;
					height: 138px;
				}

				.search {
					margin-top: 8px;
				}

				.location-tags {
					margin-top: 15px;
				
					.location-tag > a {
						height: 66px;
						font-size: 17px;
						
						&:hover {
							font-size: 20px;
						}
					}
				}
			}

			&.signed-in {
				padding-top: 15px;

				#map {
					margin-top: 12px;
					height: 160px;
				}

				.location-tags {
					margin-top: 20px;

					.location-tag > a {
						height: 70px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 960px) and (min-height: 700px) and (max-height: 800px) {
	.home-page {
		.home-inputs {
			&.signed-in {
				padding-top: 18px;

				#map {
					margin-top: 15px;
					height: 170px;
				}

				.location-tags {
					margin-top: 25px;

					.location-tag > a {
						height: 73px;
					}
				}
			}
			&.signed-out {
				padding-top: 12px;

				.signin-signup {
					a {
						padding-top: 3px;
						padding-bottom: 3px;
						height: 55px;
						
						i {
							font-size: 1.8em;
						}
					}
				}

				#map {
					margin-top: 9px;
					height: 145px;
				}

				.search {
					margin-top: 9px;
				}

				.location-tags {
					margin-top: 22px;
				
					.location-tag > a {
						height: 72px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 960px) and (min-height: 800px) and (max-height: 900px) {
	.home-page {
		.home-inputs {
			&.signed-in {
				padding-top: 20px;

				#map {
					height: 180px;
				}

				.location-tags {
					margin-top: 30px;

					.location-tag > a {
						height: 85px;
					}
				}
			}

			&.signed-out {
				padding-top: 15px;

				.signin-signup {
					a {
						padding-top: 5px;
						padding-bottom: 5px;
						height: 60px;

						i {
							font-size: 2em;
						}
					}
				}

				#map {
					margin-top: 18px;
					height: 175px;
				}

				.search {
					margin-top: 12px;
				}

				.location-tags {
					margin-top: 30px;
				
					.location-tag > a {
						height: 78px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 960px) and (min-height: 900px) and (max-height: 1000px) {
	.home-page {
		.home-inputs {
			&.signed-in {
				padding-top: 20px;

				#map {
					height: 190px;
				}

				.location-tags {
					margin-top: 35px;

					.location-tag > a {
						height: 97px;
					}
				}
			}

			&.signed-out {
				padding-top: 20px;

				.signin-signup {
					a {
						padding-top: 8px;
						padding-bottom: 8px;
						height: 65px;

						i {
							font-size: 2.1em;
						}
					}
				}

				#map {
					height: 185px;
				}

				.search {
					margin-top: 15px;
				}

				.location-tags {
					margin-top: 35px;
				
					.location-tag > a {
						height: 95px;
					}
				}
			}
		}
	}
}